import React, { SetStateAction, createContext, useEffect, Dispatch, useState } from 'react'
import { CaseCategoryType, CaseStatusType, CaseType, CaseTypeType } from '../types'
import { useAxiosInstance } from '../api/AxiosInstanceContext'

type SaveCaseProps = Partial<CaseType> & { accepted_waiver_id: number | undefined };

type updateCaseType = { caseId: number, caseName: string, caseType: string, caseCategories: CaseCategoryType[] };

interface CasesContext {
  cases: CaseType[];
  casesError: string | null;
  saveCase: (caseData: SaveCaseProps) => Promise<boolean>;
  savedCase: CaseType | null;
  savedCaseError: string | null;
  setSavedCaseError: Dispatch<SetStateAction<string | null>>;
  saveCasePurpose: (caseId: number, purpose: string) => void;
  savedCasePurpose: string | null;
  savedCasePurposeError: string | null;
  setSavedCasePurposeError: Dispatch<SetStateAction<string | null>>;
  refreshCases: () => void;
  getCase: (caseId: number) => CaseType | undefined;
  toggleCaseStatus: (caseId: number, submit: boolean) => void;
  updateCase: (updatePayload: updateCaseType) => Promise<void>;
  deleteCase: ({ caseId }: { caseId: number }) => Promise<void>;
  caseCategories: CaseCategoryType[] | null;
  displayCaseStatuses: CaseStatusType[] | undefined;
  setDisplayCaseStatuses: Dispatch<SetStateAction<CaseStatusType[] | undefined>>;
}

export const CasesContext = createContext<CasesContext>(undefined as any)

interface Props {
  children: React.ReactNode
}

export const CasesProvider: React.FC<Props> = ({ children }) => {
  const axiosInstance = useAxiosInstance();
  const [cases, setCases] = useState([] as CaseType[]);
  const [error, setError] = useState<string | null>(null);
  const [savedCase, setSavedCase] = useState<CaseType | null>(null);
  const [savedCaseError, setSavedCaseError] = useState<string | null>(null);
  const [toggleCaseStatusError, setToggleCaseStatusError] = useState<string | null>(null);
  const [savedCasePurpose, setSavedCasePurpose] = useState<string | null>(null);
  const [savedCasePurposeError, setSavedCasePurposeError] = useState<string | null>(null);
  const [deleteCaseError, setDeleteCaseError] = useState<string | null>(null);
  const [updateCaseError, setUpdateCaseError] = useState<string | null>(null);
  const [caseCategories, setCaseCategories] = useState<CaseCategoryType[] | null>(null);
  const [displayCaseStatuses, setDisplayCaseStatuses] = useState<CaseStatusType[] | undefined>();

  const getCasesData = () => {
    axiosInstance.get('/cases')
      .then((response) => setCases(response.data))
      .catch((e) => {
        setError(e);
        setCases([]);
      });

    axiosInstance.get('/case_categories')
      .then((response) => setCaseCategories(response.data))
      .catch((e) => setCaseCategories([]))

    axiosInstance.get('/case_display_statuses')
      .then((response) => setDisplayCaseStatuses(response.data))
      .catch((e) => setDisplayCaseStatuses([]));
  }

  useEffect(() => {
    getCasesData();
  }, [])

  const refreshCases = () => {
    getCasesData();
  };

  const saveCase = async (caseData: Partial<SaveCaseProps>) => {
    const { data } = await axiosInstance.post('/case/', JSON.stringify({ ...caseData }));
    if (data) {
      const casesIdsArray = cases.map((obj: CaseType) => obj.id);
      if (casesIdsArray.includes(data.id)) {
        const newCasesArray = cases.map((item: CaseType) =>
          item.id === data.id ? data : item,
        )
        setCases(newCasesArray);
      } else {
        setCases([...cases, ...[data]])
      }
      setSavedCase(data);
      return data;
    }
    return null;
  }

  const updateCase = async ({ caseId, caseName, caseType, caseCategories }: updateCaseType) => {
    await axiosInstance.post(
      '/update_case/',
      JSON.stringify({
        case_id: caseId,
        case_name: caseName,
        case_type: caseType,
        case_categories: caseCategories,
      }))
      .then(({ data }) => {
        const updatedCases = cases.map((c) => {
          if (c.id === caseId) {
            return data;
          }
          return c;
        });
        setCases(updatedCases);
      }).catch((e) => setUpdateCaseError(e));
  }

  const deleteCase = async ({ caseId }: { caseId: number }) => {
    await axiosInstance.delete(
      '/case/',
      {
        data: JSON.stringify({
          case_id: caseId,
        })
      })
      .then(() => {
        setCases(cases.filter((c) => c.id !== caseId));
      }).catch((e) => setDeleteCaseError(e));
  }

  const saveCasePurpose = (caseId: number, casePurpose: string) => {
    axiosInstance.post('/case_purpose/', JSON.stringify({ id: caseId, purpose: casePurpose }))
      .then(({ data }) => {
        if (data) {
          const casesIdsArray = cases.map((obj: CaseType) => obj.id);
          if (casesIdsArray.includes(data.id)) {
            const newCasesArray = cases.map((item: CaseType) =>
              item.id === data.id ? data : item,
            )
            setCases(newCasesArray);
          } else {
            setCases([...cases, ...[data]])
          }
          setSavedCase(data);
          return data;
        }
        return null;
      })
      .catch((e) => {
        console.log('save case purpose error: ' + e);
      })

  }


  const toggleCaseStatus = async (caseId: number, submit: boolean) => {
    await axiosInstance.post(
      '/add_case_status/',
      JSON.stringify({
        case_id: caseId,
        submit: submit,
      }))
      .then(({ data }) => {
        const updatedCases = cases.map((c) => {
          if (c.id === caseId) {
            c.statuses.push(data)
          }
          return c;
        });
        setCases(updatedCases);
      }).catch((e) => setToggleCaseStatusError(e));
  }

  const getCase = (caseId: number) => {
    if (caseId && cases && cases !== undefined) {
      return cases.find((c: CaseType) => c.id === caseId);
    }
  };


  const value = {
    cases: cases ? (cases as CaseType[]) : ([] as CaseType[]),
    casesError: error,
    saveCase,
    savedCase,
    savedCaseError: savedCaseError,
    setSavedCaseError,
    saveCasePurpose,
    savedCasePurpose,
    setSavedCasePurpose,
    savedCasePurposeError,
    setSavedCasePurposeError,
    refreshCases,
    getCase,
    toggleCaseStatus,
    updateCase,
    deleteCase,
    caseCategories,
    displayCaseStatuses,
    setDisplayCaseStatuses
  }

  return <CasesContext.Provider value={value}>{children}</CasesContext.Provider>
}