import * as React from 'react'
import { CaseType, SessionType, User } from '../../types'
import { ClientsContext } from '../../context/clientsContext'
import _ from 'lodash'
import { SearchContext } from '../../context/searchContext'
import { Add, KeyboardArrowDown, KeyboardArrowUp, Settings } from '@mui/icons-material'
import { Box } from '@mui/system'
import { Button, Collapse, Divider, Grid, Tooltip, useMediaQuery, ClickAwayListener, ListItem, List } from '@mui/material'
import theme from '../../../src/Styles/CoreTheme'
import Typography from '../../routes/LandingPage/Typography'
import { CasesContext } from '../../context/casesContext'
import { SessionsContext } from '../../context/sessionsContext'
import { DateTime } from 'luxon'
import { ProtocolsContext } from '../../context/protocolsContext'
import { DialogsContext } from '../../context/dialogsContext'
import CaseRowItem from '../CaseRowItem.tsx'
import '../../css/rotateIcon.css'
import { displayEditEnabledCaseStates } from '../../constants'
import { CurrentCohortSubscriptionContext } from '../../context/currentCohortSubscriptionContext'


export const getDisplayCaseState = (clientCase: CaseType) => {
  if (clientCase && clientCase.statuses && clientCase.statuses.length > 0) {
    const latestCaseStatus = _.maxBy(clientCase.statuses, 'created_date');
    if (latestCaseStatus && latestCaseStatus.state) {
      return latestCaseStatus?.state.replace('_', ' ').toLowerCase();
    }
  }
  return 'none'
}

export const getDisplayCaseStateNotes = (clientCase: CaseType) => {
  if (clientCase.statuses && clientCase.statuses.length > 0) {
    return _.maxBy(clientCase.statuses, 'created_date')?.notes;
  }
  return '';
}

export const getClientCaseSessions = (sessions: SessionType[], clientCase: CaseType) => {
  if (clientCase) {
    const caseSessions = sessions.filter((session) => session.case === clientCase.id);
    return caseSessions.sort((a, b) => DateTime.fromISO(b.date).toMillis() - DateTime.fromISO(a.date).toMillis())
  }
  return [];
}

const ClientsTable = () => {
  const { clients } = React.useContext(ClientsContext);
  const { cases, toggleCaseStatus } = React.useContext(CasesContext);
  const { sessions } = React.useContext(SessionsContext);
  const { protocols } = React.useContext(ProtocolsContext);
  const [filtered, setFiltered] = React.useState<User[]>();
  const { clientInfoSubstring } = React.useContext(SearchContext);
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [openClientRows, setOpenClientRows] = React.useState<number[]>([]);
  const [openStatusRows, setOpneStatusRows] = React.useState<number[]>([]);
  const [openSessionRows, setOpenSessionRows] = React.useState<number[]>([]);
  const { showSessionDialog, showCaseDialog, setClientMenuDialog } = React.useContext(DialogsContext);
  const [settingsIconRotateId, setSettingsIconRotateId] = React.useState<null | number>(null);
  const { currentCohortSubscription } = React.useContext(CurrentCohortSubscriptionContext);

  React.useEffect(() => {
    if (clients && clientInfoSubstring && !_.isEmpty(clientInfoSubstring)) {
      if (clientInfoSubstring.length < 1) {
        setFiltered(undefined);
      } else {
        const result = clients.filter((c) => {
          const name = c.full_name.toLowerCase();
          const email = c.email.toLowerCase();
          const nameMatch = name.includes(clientInfoSubstring.toLowerCase());
          const emailMatch = email.includes(clientInfoSubstring.toLowerCase());
          return nameMatch || emailMatch;
        });
        setFiltered(result);
      }
    }
  }, [clientInfoSubstring, clients])

  const getClientName = (client: User) => {
    const { full_name, first_name, last_name, email } = client;
    if (full_name) {
      return full_name;
    } else if (first_name) {
      return first_name;
    } else if (last_name) {
      return last_name;
    } else {
      return email
    }
  }

  const toggleClientRow = (id: number) => {
    if (openClientRows.includes(id)) {
      setOpenClientRows(_.remove(openClientRows, (existingId) => existingId != id));
    } else {
      setOpenClientRows([...[id], ...openClientRows]);
    }
  };

  const toggleSessionRow = (id: number) => {
    if (openSessionRows.includes(id)) {
      setOpenSessionRows(_.remove(openSessionRows, (existingId) => existingId != id));
    } else {
      setOpenSessionRows([...[id], ...openSessionRows]);
    }
  };

  const toggleStatusRow = (id: number) => {
    if (openStatusRows.includes(id)) {
      setOpneStatusRows(_.remove(openStatusRows, (existingId) => existingId != id));
    } else {
      setOpneStatusRows([...[id], ...openSessionRows])
    }
  }

  const getClientCases = (client: User) => {
    if (cases) {
      return cases.filter((caseClient) => {
        if (typeof caseClient.client === 'number') {
          return caseClient.client === client.id;
        } else {
          return caseClient.client.id === client.id
        }
      });
    }
    return [];
  }

  const Cases = ({ client }: { client: User }) => {
    const clientCases = getClientCases(client);
    if (clientCases && clientCases.length > 0) {
      return (
        <Box sx={{ backgroundColor: 'whitesmoke' }}>
          <Typography color={'CaptionText'} variant='subtitle1' sx={{ fontWeight: 'bold', ml: 2, pt: 1 }}>CASES</Typography>
          <Divider />
          <Box flexDirection={'row'} display={'flex'}>
            <Box sx={{ mb: 0 }} width={'100%'}>
              {clientCases.map((clientCase: CaseType, i, row) => {
                const createdDate = DateTime.fromISO(clientCase.created_date).toLocaleString(DateTime.DATE_SHORT);
                // const isDescriptionOpen = openDescriptionRows.includes(clientCase.id);
                // const isSessionsOpen = openSessionRows.includes(clientCase.id);
                const caseSessions = getClientCaseSessions(sessions, clientCase);
                return (
                  <Box key={clientCase.id}>
                    <CaseRowItem
                      rowCase={clientCase}
                      rowCaseSessions={caseSessions}
                      toggleStatusRow={() => toggleStatusRow(clientCase.id)}
                      toggleSessionRow={() => toggleSessionRow(clientCase.id)}
                      openStatusRows={openStatusRows}
                      openSessionRows={openSessionRows} />
                    {i + 1 !== row.length && <Box sx={{ width: '100%', backgroundColor: 'white', height: 10, mt: 0 }} />}
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>
      )
    } else {
      return (
        <Box flexDirection={'row'} display={'flex'} sx={{ backgroundColor: 'whitesmoke' }}>
          <Typography m={2} color='CaptionText'>No cases yet</Typography>
        </Box>
      )
    }
  }

  return (
    <List style={{ height: (isSmUp && isSmDown ? '80vh' : '80vh') }} sx={{ overflowY: 'scroll' }}>
      {(clientInfoSubstring && filtered ? filtered : clients || []).map((client, i) => {
        const showEmail = client.email !== '--' && client.full_name;
        const detailsOpen = openClientRows.includes(client.id);
        return (
          <div key={client.id}>
            <ListItem sx={{ flexDirection: 'column', px: 0, mx: 0, py: 0, my: 0 }}>
              <Grid container sx={{ mx: 0, px: 2, my: 1, py: 0 }}>
                <Grid item xs={12} sm={12} md={5}>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }} overflow={'hidden'}>
                    <Typography variant='h6' color='CaptionText' noWrap>{getClientName(client)}</Typography>
                  </Box>
                </Grid>
                <Grid item container xs={12} sm={12} md={7} spacing={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: isSmDown ? 'flex-start' : 'flex-end', ...(isSmDown ? { mt: 1 } : {}) }}>
                  <Grid item>
                    {currentCohortSubscription?.state === 'ACTIVE' && <Button variant={'outlined'} onClick={() => showCaseDialog({ show: true, clientId: client.id })} startIcon={<Add />}>case</Button>}
                  </Grid>
                  <Grid item>
                  {currentCohortSubscription?.state === 'ACTIVE' && <Button variant={'outlined'} onClick={() => showSessionDialog({ show: true, clientId: client.id, caseId: undefined })} startIcon={<Add />}>session</Button>}
                  </Grid>
                  <Grid item>
                    <Button variant={'outlined'} onClick={() => toggleClientRow(client.id)} endIcon={detailsOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}>{'details'}</Button>
                  </Grid>
                  <Grid item>
                  {currentCohortSubscription?.state === 'ACTIVE' && <Button variant={'text'} onClick={() => {
                      setSettingsIconRotateId(settingsIconRotateId ? null : client.id);
                      setClientMenuDialog({ show: true, clientId: client.id })
                    }} sx={{ mx: 0, px: 1, minWidth: 0 }} ><Settings className={settingsIconRotateId === client.id ? 'rotated' : 'counter-rotate'} /></Button>}
                  </Grid>
                </Grid>
              </Grid>
              <Collapse sx={{ width: '100%' }} in={detailsOpen} timeout='auto' unmountOnExit>
                <Cases client={client} />
              </Collapse>
            </ListItem>
            {i !== clients.length - 1 && <Divider sx={{ py: 0, my: 0 }} />}
          </div>
        )
      })}
    </List >
  )
}

export default ClientsTable