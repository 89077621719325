import { useContext, useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import './App.css'
import Core from './routes/Core'
import RequireAuth from './componenents/requireAuth'
import LandingPage from './routes/LandingPage/LandingPage'
import { routes } from './routes/routes'
import { UserContext, UserProvider } from './context/userContext'
import { AuthContext } from './context/authContext'
import { WaiverProvider } from './context/waiversContext'
import { ProfileFormProvider } from './componenents/ProfileData/ProfileFormContext'
import { ProtocolsProvider } from './context/protocolsContext'
import { AreasProvider } from './context/areasContext'
import { CreatedAreasProvider } from './context/createdAreasContext'
import { CreatedProtocolsProvider } from './context/createdProtocolsContext'
import { SessionsProvider } from './context/sessionsContext'
import { CasesProvider } from './context/casesContext'
import { DialogsProvider } from './context/dialogsContext'
import { AreasStateProvider } from './context/areasSateContext'
import { SearchProvider } from './context/searchContext'
import { RecommendationsProvider } from './context/recommendationsContext'
import { ReviewCasesProvider } from './context/review/ReviewCasesContext'
import ReviewCore from './routes/ReviewCore'
import { LonginDialogsProvider } from './context/loginDialogsContext'
import { AxiosInstanceProvider } from './api/AxiosInstanceContext'
import { ClientsProvider } from './context/clientsContext'
import Loading from './componenents/Loading'
import { ReviewSessionsProvider } from './context/review/ReviewSessionsContext'
import { FiltersProvider } from './context/filtersContetxt'
import { CohortsSubscriptionsContext, CohortsSubscriptionsProvider } from './context/cohortSubscriptionsContext'
import { CurrentCohortSubscriptionContext, CurrentCohortSubscriptionProvider } from './context/currentCohortSubscriptionContext'

const Dashboard = () => {
  const { user } = useContext(UserContext);
  const { currentCohortSubscription } = useContext(CurrentCohortSubscriptionContext);
  const { cohortsSubscriptions } = useContext(CohortsSubscriptionsContext);

  return (
    <>
      {currentCohortSubscription || cohortsSubscriptions.length === 0?
        (
          <ProfileFormProvider>
            <WaiverProvider>
              <AreasProvider>
                <ProtocolsProvider>
                  <RecommendationsProvider>
                    <SearchProvider>
                      {user && user.is_case_reviewer ? (
                        <FiltersProvider>
                          <ReviewCasesProvider>
                            <ClientsProvider>
                              <ReviewSessionsProvider>
                                <ReviewCore />
                              </ReviewSessionsProvider>
                            </ClientsProvider>
                          </ReviewCasesProvider>
                        </FiltersProvider>
                      ) : (
                        <CasesProvider>
                          <ClientsProvider>
                            <AreasStateProvider>
                              <CreatedAreasProvider>
                                <CreatedProtocolsProvider>
                                  <SessionsProvider>
                                    <Core />
                                  </SessionsProvider>
                                </CreatedProtocolsProvider>
                              </CreatedAreasProvider>
                            </AreasStateProvider>
                          </ClientsProvider>
                        </CasesProvider>
                    )}
                  </SearchProvider>
                </RecommendationsProvider>
              </ProtocolsProvider>
            </AreasProvider>
          </WaiverProvider>
        </ProfileFormProvider>
        ) :
        (
          <Loading/>
        )
    }
    </>
  )
}

function App() {
  const navigate = useNavigate()
  const { currentUser, idToken } = useContext(AuthContext)

  // todo tracker do I need this?
  // can put this inside the auth provider maybe?
  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard')
    }
  }, [currentUser])

  return (
    <Routes>
      <Route index path='/' element={
        <LonginDialogsProvider>
          <LandingPage />
        </LonginDialogsProvider>
      } />
      <Route
        path={routes.DASHBOARD}
        element=
        {idToken ? (
          <CurrentCohortSubscriptionProvider>
            <AxiosInstanceProvider>
              <DialogsProvider>
                <UserProvider>
                  <CohortsSubscriptionsProvider>
                    <Dashboard />
                  </CohortsSubscriptionsProvider>
                </UserProvider>
              </DialogsProvider>
            </AxiosInstanceProvider>
          </CurrentCohortSubscriptionProvider>
        ) : (<></>)}
      />
    </Routes >
  )
}

export default App
