import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import RefreshIcon from '@mui/icons-material/Refresh'
import { ClientsContext } from '../../context/clientsContext'
import { Avatar, Box, Divider, LinearProgress, List, ListItem, ListItemAvatar, ListItemText, useMediaQuery } from '@mui/material'
import { Add, ArrowForward, ChevronRight, Email, Person } from '@mui/icons-material'
import ClientsTable from './ClientsTable'
import AddClientDialog from './AddClientDialog'
import { SearchContext } from '../../context/searchContext'
import { DialogsContext } from '../../context/dialogsContext'
import theme from '../../Styles/CoreTheme'
import { SessionsContext } from '../../context/sessionsContext'
import { CasesContext } from '../../context/casesContext'
import { CurrentCohortSubscriptionContext } from '../../context/currentCohortSubscriptionContext'

const ClientsTab = () => {
  const { clients, refreshClients } = React.useContext(ClientsContext);
  const { refreshCases } = React.useContext(CasesContext);
  const { refreshSessions } = React.useContext(SessionsContext);

  const { clientInfoSubstring, setClientInfoSubstring } = React.useContext(SearchContext);
  const { setShowAddClientDialog } = React.useContext(DialogsContext);
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { currentCohortSubscription } = React.useContext(CurrentCohortSubscriptionContext);



  const getClientsTable = () => {
    if (!clients) {
      return <Box sx={{ margin: 10, marginTop: 20, marginBottom: 20 }}> <LinearProgress /></Box >;
    } else if (clients.length < 1) {
      return (
        <Box m={5}>
          <Typography sx={{ margin: 'auto' }} color='text.secondary' align='center'>
            No Clients Yet
          </Typography>
        </Box>)
    } else {
      return <ClientsTable />;
    }
  }

  const refreshCoreData = () => {
    refreshClients();
    refreshCases();
    refreshSessions();
  }

  return (
    <>
      <Paper sx={{ maxWidth: 936, margin: 'auto', marginBottom: 2, overflow: 'hidden' }}>
        <AppBar
          position='static'
          color='default'
          elevation={0}
          sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems='center' direction={'row'}>
              <Grid item>
                <SearchIcon color='inherit' sx={{ display: 'block' }} />
              </Grid>
              <Grid item xs>
                <TextField
                  value={clientInfoSubstring}
                  fullWidth
                  placeholder='Search Clients'
                  InputProps={{
                    disableUnderline: true,
                    sx: { fontSize: 'default' },
                  }}
                  variant='standard'
                  onChange={(e) => { setClientInfoSubstring(e.target.value) }}
                />
              </Grid>
              <Grid item xs container justifyContent='flex-end'>
                <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
                {currentCohortSubscription?.state === 'ACTIVE' && <Button variant='contained' sx={{ m: 1 }} onClick={() => setShowAddClientDialog(true)}>
                    {!isSmDown ? 'Add Client' : <Add />}
                  </Button>}
                  <Tooltip title='Reload' onClick={() => refreshCoreData()}>
                    <IconButton>
                      <RefreshIcon color='inherit' sx={{ display: 'block' }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Paper>
      <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }} elevation={1}>
        {getClientsTable()}
      </Paper>
    </>
  )
}

export default ClientsTab
