import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CohortsSubscriptionsContext } from '../../context/cohortSubscriptionsContext';
import { CohortSubscriptionType } from '../../types';
import { CurrentCohortSubscriptionContext } from '../../context/currentCohortSubscriptionContext';
import { CasesContext } from '../../context/casesContext';
import { SessionsContext } from '../../context/sessionsContext';
import { ClientsContext } from '../../context/clientsContext';

export const CohortSelectorDialog = () => {
  const { cohortsSubscriptions } = React.useContext(CohortsSubscriptionsContext);
  const { currentCohortSubscription, setCurrentCohortSubscription } = React.useContext(CurrentCohortSubscriptionContext);
  const { refreshCases } = React.useContext(CasesContext);
  const { refreshSessions } = React.useContext(SessionsContext);
  const { refreshClients } = React.useContext(ClientsContext);

    React.useEffect(() => {
        if (currentCohortSubscription) {
            refreshClients();
            refreshCases();
            refreshSessions()
        }
    }, [currentCohortSubscription])

    return (
        <Box sx={{ maxWidth: 936, margin: 'auto', marginBottom: 2, overflow: 'hidden' }}>

            <FormControl>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentCohortSubscription && currentCohortSubscription.id}
                variant='standard'
                disableUnderline
                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
            >
                {cohortsSubscriptions.map((sub: CohortSubscriptionType) => (
                    <MenuItem
                        key={sub.id}
                        value={sub.id}
                        onClick={() => setCurrentCohortSubscription(sub)}>
                            {sub.cohort.name}
                    </MenuItem>
                ))}
            </Select>
            </FormControl>
        </Box>
    );
}