import * as React from 'react'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {
    Autocomplete,
    Box,
    Button,
    Divider,
    Grid,
    useMediaQuery,
} from '@mui/material'
import { ClientsContext } from '../context/clientsContext'
import { CasesContext } from '../context/casesContext'
import { DialogsContext } from '../context/dialogsContext'
import { Controller, useForm } from 'react-hook-form'
import { CaseType, User, WaiverType, caseTypeOptions, CaseTypeType, CaseCategoryType, FakeUser } from '../types'
import { LoadingButton } from '@mui/lab'
import ConfirmDialog from './ConfirmDialog'
import { theme } from './SessionsTab/AreaStateSetterStyle'
import { getDisplayCaseState } from './ClientsTab/ClientsTable'
import { displayEditEnabledCaseStates } from '../constants'


export default function ManageCaseDialog() {
    const { showManageCaseDialog, manageCaseDialogState } = React.useContext(DialogsContext);
    const { cases, updateCase, deleteCase, caseCategories, toggleCaseStatus, refreshCases } = React.useContext(CasesContext);
    const { clients, resendWaiver } = React.useContext(ClientsContext);
    const [loading, setLoading] = React.useState(false);
    const [loadingSubmit, setLoadingSubmit] = React.useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
    const [savedCaseClient, setSavedCaseClient] = React.useState<User | null>(null);
    const [caseToEdit, setCaseToEdit] = React.useState<CaseType | null>(null);
    const [client, setClient] = React.useState<User | null>(null);
    const [clientWaiverAccepted, setClientWaiverAccepted] = React.useState<boolean | null>(null);
    const [submitButtonText, setSubmitButtonText] = React.useState('Submit for Review');
    const [showWaiverIssueDialog, setShowWaiverIssueDialog] = React.useState(false);
    const [caseStatus, setCaseStatus] = React.useState<string | null>(null);
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const { control, setValue, getValues, handleSubmit } = useForm<AddCaseType>({
        defaultValues:
        {
            name: '',
            client: FakeUser,
            type: { id: 0, label: '' },
            categories: [] as CaseCategoryType[],
        }

    });

    type AddCaseType = {
        name: string;
        client: User | undefined;
        type: CaseTypeType | undefined;
        categories: CaseCategoryType[];
    }

    React.useEffect(() => {
        if (manageCaseDialogState) {
            const tempCase = cases.find((c) => c.id === manageCaseDialogState.caseId);
            if (tempCase) {
                setCaseToEdit(tempCase);
                setCaseStatus(getDisplayCaseState(tempCase));
                const tempClient = clients.find((c) => c.id === tempCase?.client);
                if (tempClient) {
                    setClient(tempClient);
                }
            }
        }
    }, [manageCaseDialogState, cases, clients])

    const handleClose = () => {
        showManageCaseDialog({ show: false });
        setValue('name', '');
        setValue('type', undefined);
        setValue('client', undefined);
        setLoading(false);
    }

    const handleUpdate = async () => {
        setLoading(true);
        const { client: formClient, name, type, categories } = getValues();
        if (caseToEdit && formClient != null && type && categories) {
            const newCase = {
                caseId: caseToEdit.id,
                caseName: name,
                caseType: type.label.toLowerCase(),
                caseCategories: categories.filter((c) => c.id !== 0)
            }
            await updateCase(newCase)
                .then(() => {
                    setSavedCaseClient(formClient);
                })
                .catch((e) => {
                    console.error('handleUpdate case error: ' + e);
                    setLoading(false);
                });
            refreshCases();
            handleClose();
        }
        setLoading(false);
    }

    const submit = async () => {
        if (clientWaiverAccepted || !client?.tracked) {
            setLoadingSubmit(true);
            await handleUpdate();
            if (caseToEdit) {
                await toggleCaseStatus(caseToEdit.id, caseStatus === 'submitted' ? false : true);
            }
            setLoadingSubmit(false);
        } else {
            setShowWaiverIssueDialog(true);
        }
    }

    const handleDelete = async () => {
        setLoading(true);
        if (caseToEdit) {
            const data = {
                caseId: caseToEdit.id,
            }
            await deleteCase(data)
                .catch((e) => {
                    console.error('handleDelete case error: ' + e);
                });
            handleClose();
        }
        setLoading(false);
    }

    React.useEffect(() => {
        if (manageCaseDialogState && manageCaseDialogState.caseId && caseToEdit && client) {
            const tempType = caseTypeOptions.find((t) => t.label.toLowerCase() === caseToEdit?.type);
            setValue('name', caseToEdit?.name || '');
            setValue('type', tempType);
            setValue('client', client);
            setValue('categories', caseToEdit?.categories == undefined ? [] as CaseCategoryType[] : caseToEdit?.categories);
        }
    }, [manageCaseDialogState, caseToEdit, client]);

    React.useEffect(() => {
        if (caseToEdit) {
            setSubmitButtonText(caseStatus === 'submitted' ? 'Unsubmit' : 'Submit for Review');
        }
    }, [cases, caseToEdit]);

    React.useEffect(() => {
        if (caseToEdit && client && client.tracked) {
            const waiverResult = client?.waivers_results.find((wr) => wr.case_id === caseToEdit.id && wr.waiver_id === 2);
            setClientWaiverAccepted(waiverResult?.accepted === 'Y');
        }
    }, [cases, caseToEdit, client]);

    const getIsSubmitCaseDisabled = () => {
        if (caseStatus && displayEditEnabledCaseStates.includes(caseStatus)) {
            return false;
        }
        return true;
    }

    const isUpdateDisabled = () => {
        if (caseStatus) {
            return !displayEditEnabledCaseStates.includes(caseStatus);
        }
        return true;
    }

    return (
        <div>
            <Dialog open={!!manageCaseDialogState.show} onClose={handleClose} keepMounted={false}>
                <DialogTitle>{'Manage Case'}</DialogTitle>
                <Divider />
                <DialogContent sx={{ minWidth: isSmDown ? 300 : 500 }}>
                    <form onSubmit={handleSubmit(submit)}>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    label="Case Name (avoid using client name)"
                                    variant="outlined"
                                    {...field}
                                    required
                                />
                            )}
                        />
                        <Box m={1} />
                        <Controller
                            control={control}
                            name='client'
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    disabled={true}
                                    noOptionsText={'No Clients'}
                                    onChange={(event, client) => {
                                        onChange(client)
                                    }}
                                    value={value}
                                    options={clients}
                                    getOptionLabel={(client) => (client ? client.full_name || client.email : '')}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label='Client'
                                            margin='normal'
                                            variant='outlined'
                                            required
                                        />
                                    )}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name='type'
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    noOptionsText={'No Types'}
                                    onChange={(event, type) => onChange(type)}
                                    value={value}
                                    options={caseTypeOptions}
                                    getOptionLabel={(type) => (type ? type.label : '')}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label='Case Type'
                                            margin='normal'
                                            variant='outlined'
                                            required
                                        />
                                    )}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name='categories'
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    multiple
                                    noOptionsText={'No Categories Selected'}
                                    onChange={(event, type) => onChange(type)}
                                    value={value}
                                    options={[...caseCategories || [], ...[{ id: 0, name: 'None of the above' }]]}
                                    getOptionLabel={(c) => c.name}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label='Case Categories'
                                            margin='normal'
                                            variant='outlined'
                                            InputProps={{
                                                ...params.InputProps,
                                                required: value.length === 0
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                        <Box m={2} />
                        <Grid item>
                            <LoadingButton disabled={isUpdateDisabled()} loading={loading && !loadingSubmit} variant='contained' onClick={() => caseStatus != 'submitted' ? handleUpdate() : alert('cant edit Submitted case')} color='primary' fullWidth>
                                Update
                            </LoadingButton>
                            <Box m={1} />
                            <LoadingButton disabled={getIsSubmitCaseDisabled()} loading={loadingSubmit} variant='contained' type={'submit'} color='secondary' fullWidth>
                                {submitButtonText}
                            </LoadingButton>
                            <Box m={3} />
                            <Button variant='text' disabled={isUpdateDisabled()} onClick={() => setShowConfirmDialog(true)} color='error' fullWidth>
                                Delete
                            </Button>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
            <ConfirmDialog
                open={showWaiverIssueDialog}
                title="Submission Error"
                message="Client has not accepted healing waiver. The client can accept the waiver by using the 'client login' at pranarecords.app or you can send them a link to their email by selecting 'Resend Waiver'."
                onConfirm={() => {
                    if (caseToEdit) {
                        resendWaiver(caseToEdit.id);
                    }
                    setShowWaiverIssueDialog(false);
                }}
                onCancel={() => {
                    setShowWaiverIssueDialog(false);
                }}
                confirmText='Resend Waiver'
                cancelText='Ok'
            />
            <ConfirmDialog
                open={showConfirmDialog}
                title="Warning"
                message="Deleting this Case will also erase all Sessions linked to it, you can not undo this. Do you want to continue?"
                onConfirm={() => {
                    setShowConfirmDialog(false);
                    handleDelete();
                }}
                onCancel={() => {
                    setShowConfirmDialog(false);
                }}
            />
        </div>
    )
}
