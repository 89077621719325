import React, { createContext, useContext, useMemo } from 'react';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AuthContext } from '../context/authContext';
import { CohortSubscriptionType } from '../types';
import { CurrentCohortSubscriptionContext } from '../context/currentCohortSubscriptionContext';

interface AxiosInstanceContextProps {
    axiosInstance: AxiosInstance;
}

const AxiosInstanceContext = createContext<AxiosInstanceContextProps | undefined>(undefined);

export const useAxiosInstance = () => {
    const context = useContext(AxiosInstanceContext);
    if (!context) {
        throw new Error('useAxiosInstance must be used within an AxiosInstanceProvider');
    }
    return context.axiosInstance;
};

interface ProviderProps {
    children: React.ReactNode
}

const addCohortSubscriptionSelected = (config: AxiosRequestConfig, cohortSubscription: CohortSubscriptionType) => {
    if (config.method === 'get') {
        config.params = { ...config.params, ...{ cohort: cohortSubscription.cohort.id }}
    } else {
        const dataObj = JSON.parse(config.data);
        const updatedConfig = {
            ...dataObj,
            cohort: cohortSubscription.cohort.id
        }
        config.data = JSON.stringify(updatedConfig);
    }
}

const subscriptionEndpoints = [
    '/sessions',
    '/created_areas',
    '/clients',
    '/cases',
    '/case_categories',
    '/case_display_statuses',
    '/recommendations',
    '/protocols',
    '/areas',
]

export const AxiosInstanceProvider: React.FC<ProviderProps> = ({ children }) => {
    const { currentUser, idToken, idTokenResult } = useContext(AuthContext);
    const { currentCohortSubscription } = useContext(CurrentCohortSubscriptionContext);

    const axiosInstance = useMemo(() => {
        const instance = axios.create({
            baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API : process.env.REACT_APP_PROD_API,
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
        });

        const refreshAccessToken = async () => {
            try {
                if (currentUser) {
                    const refreshedUser = await currentUser.getIdToken(true);
                    if (refreshedUser) {
                        return refreshedUser;
                    }
                }
            } catch (error) {
                console.error('Token refresh error:', error);
            }
            return null;
        };

        instance.interceptors.request.use(
            async (config) => {
                config.headers.setContentType('application/json');
                if (idTokenResult) {
                    const exp = new Date(idTokenResult?.expirationTime);
                    if (!currentCohortSubscription && config.url && subscriptionEndpoints.includes(config.url)) {
                        throw new axios.Cancel('No subscription');
                    }
                    if (Date.now() >= exp.getTime()) {
                        const newToken = await refreshAccessToken();
                        if (newToken) {
                            config.headers.Authorization = `Bearer ${newToken}`;
                        }
                    }
                    if (config.url && currentCohortSubscription) {
                        addCohortSubscriptionSelected(config, currentCohortSubscription);
                    }
                    if (config.url && !config.url.endsWith('/') && !config.url.includes('?')) {
                        config.url += '/';
                    }
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
        return instance;
    }, [ currentCohortSubscription ]);

    return (
        <AxiosInstanceContext.Provider value={{ axiosInstance }}>
            {children}
        </AxiosInstanceContext.Provider>
    );
};
