import * as React from 'react'
import Divider from '@mui/material/Divider'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import List from '@mui/material/List'
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import HomeIcon from '@mui/icons-material/Home'
import PeopleIcon from '@mui/icons-material/People'
import DnsRoundedIcon from '@mui/icons-material/DnsRounded'
import PermMediaOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActual'
import PublicIcon from '@mui/icons-material/Public'
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet'
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent'
import {
  Article,
  AutoGraph,
  Favorite,
  FolderCopy,
  HistoryEdu,
  Inventory,
  Pattern,
  PersonAddAlt1,
  Public,
  Shield,
  Spa,
} from '@mui/icons-material'
import { DialogsContext } from '../context/dialogsContext'
import { LinearProgress, LinearProgressProps, Tooltip, Typography } from '@mui/material'
import { CasesContext } from '../context/casesContext'
import { ClientsContext } from '../context/clientsContext'
import { SessionsContext } from '../context/sessionsContext'
import WaiverDialog from './WaiversDialogs/WaiverDialog'
import { WaiverContext } from '../context/waiversContext'
import publicNotice from '../data/publicNotice'
import _ from 'lodash'
import { UserContext } from '../context/userContext'
import { ReviewCasesContext } from '../context/review/ReviewCasesContext'
import { CaseType, User } from '../types'
import { CurrentCohortSubscriptionContext } from '../context/currentCohortSubscriptionContext'

const item = {
  py: '2px',

  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
}

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
}

const SectionContainer = ({ text, children }: { text: string, children: React.ReactNode }) => {
  return (
    <Box sx={{ bgcolor: '#101F33' }}>
      <ListItem sx={{ py: _.isEmpty(text) ? 0 : 2, px: 3 }}>
        <ListItemText sx={{ color: '#fff' }}>{text}</ListItemText>
      </ListItem>
      {children}
      <Divider sx={{ mt: 2 }} />
    </Box>
  )
}

const getButtonListItem = (text: string, onClick: () => void, icon: React.ReactElement) => {
  return (
    <ListItem disablePadding >
      <ListItemButton onClick={onClick} sx={item}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText sx={{ marginTop: 1.3 }}>{text}</ListItemText>
      </ListItemButton>
    </ListItem>
  )
}

const getProgressListItem = (text: string, created: number, approved: number, goal: number, user: User) => {
  const increment = 100 / goal;
  return (
    <>
      <ListItem disablePadding sx={{ ...item, ...{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' } }}>
        <ListItemText>{`${text}`}</ListItemText>
        {user.is_case_reviewer ? (
          <Tooltip title={`${created} Cases submitted or in review process`}>
            <ListItemText sx={{ textAlign: 'right' }}>{created}</ListItemText>
          </Tooltip>
        ) : (
          <Tooltip title={`${approved} Cases Approved / ${created} Cases Created`}>
            <ListItemText sx={{ textAlign: 'right' }}>{`${approved} / ${created}`}</ListItemText>
          </Tooltip>
        )}

      </ListItem>
      {/* <ListItem disablePadding>
          <Box sx={{ marginLeft: 3, width: '80%' }}>
            <LinearProgress variant='buffer' value={approved * increment} valueBuffer={created * increment} sx={{
              '& .MuiLinearProgress-dashed': {
                animation: 'none',
                backgroundImage: 'none',
                backgroundColor: 'transparent',
              }
            }} />
          </Box>
        </ListItem> */}
      <Box sx={{ m: 2 }} />
    </>
  )
}



const NavHealerConent = () => {
  const {
    setShowAddClientDialog,
    showCaseDialog,
    showSessionDialog,
    showHealingWaiverDialog,
    setShowHealingWaiverDialog,
    showPrivacyWaiverDialog,
    setShowPrivacyWaiverDialog,
    showPublicNotice,
    setShowPublicNotice
  } = React.useContext(DialogsContext);
  const { user } = React.useContext(UserContext);
  const { cases } = React.useContext(CasesContext);
  const { sessions } = React.useContext(SessionsContext);
  const { clients } = React.useContext(ClientsContext);
  const [simpleCases, setSimpleCases] = React.useState(0);
  const [complexCases, setComplexCases] = React.useState(0);
  const [psychCases, setPsychCases] = React.useState(0);
  const [approvedCases, setApprovedCases] = React.useState({ total: 0, simple: 0, complex: 0, psyh: 0 });
  const { currentCohortSubscription } = React.useContext(CurrentCohortSubscriptionContext);

  React.useEffect(() => {
    if (cases) {
      setSimpleCases(cases.filter((clientCase) => clientCase.type === 'simple').length);
      setComplexCases(cases.filter((clientCase) => clientCase.type === 'complex').length);
      setPsychCases(cases.filter((clientCase) => clientCase.type === 'psychological').length);
    }
  }, [cases])

  React.useEffect(() => {
    if (cases) {
      const approved = cases.filter((clientCase) => clientCase.statuses && clientCase.statuses.length > 0 && _.maxBy(clientCase.statuses, 'created_date')?.state === 'APPROVED');
      const simple = approved.filter((approved) => approved.type === 'simple');
      const complex = approved.filter((approved) => approved.type === 'complex')
      const psych = approved.filter((approved) => approved.type === 'psychological')
      setApprovedCases({ total: approved.length, simple: simple.length, complex: complex.length, psyh: psych.length });
    }
  }, [cases])

  return (
    <>
      <SectionContainer text='Quick Links'>
        <>
          {getButtonListItem('Add Client', () => currentCohortSubscription?.state === 'ACTIVE' && setShowAddClientDialog(true), <PersonAddAlt1 />)}
          {getButtonListItem('Create Case', () => currentCohortSubscription?.state === 'ACTIVE' && showCaseDialog({ show: true }), <Inventory />)}
          {getButtonListItem('Create Session', () => currentCohortSubscription?.state === 'ACTIVE' && showSessionDialog({ show: true }), <Favorite />)}
        </>
      </SectionContainer>
      <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', flexDirection: 'column' }}>
        <SectionContainer text={`${currentCohortSubscription?.cohort.name} Progress`}>
          <>
            {getProgressListItem('Simple Cases', simpleCases, approvedCases.simple, 10, user)}
            {getProgressListItem('Complex Cases', complexCases, approvedCases.complex, 10, user)}
            {getProgressListItem('Psychology Cases', psychCases, approvedCases.psyh, 5, user)}
            <Box sx={{ mb: 3.5 }} />
          </>
        </SectionContainer>
        <SectionContainer text=''>
          <>
            {getButtonListItem('Public Notice', () => setShowPublicNotice(true), <Public sx={{ mt: 0.2 }} />)}
          </>
        </SectionContainer>
      </Box>
    </>
  )
}

const NavReviewerContent = () => {
  const { user } = React.useContext(UserContext);
  const { reviewCases } = React.useContext(ReviewCasesContext);
  const {
    setShowPublicNotice
  } = React.useContext(DialogsContext);
  const approved = reviewCases.filter((clientCase) => clientCase.statuses && clientCase.statuses.length > 0 && _.maxBy(clientCase.statuses, 'created_date')?.state === 'APPROVED');
  const simple = approved.filter((approved) => approved.type === 'simple');
  const complex = approved.filter((approved) => approved.type === 'complex');
  const psych = approved.filter((approved) => approved.type === 'psychological');

  const [simpleCases, setSimpleCases] = React.useState(0);
  const [complexCases, setComplexCases] = React.useState(0);
  const [psychCases, setPsychCases] = React.useState(0);
  const [approvedCases, setApprovedCases] = React.useState({ total: 0, simple: 0, complex: 0, psyh: 0 });

  React.useEffect(() => {
    if (reviewCases) {
      setSimpleCases(reviewCases.filter((clientCase) => clientCase.type === 'simple').length);
      setComplexCases(reviewCases.filter((clientCase) => clientCase.type === 'complex').length);
      setPsychCases(reviewCases.filter((clientCase) => clientCase.type === 'psychological').length);
    }
  }, [reviewCases]);

  React.useEffect(() => {
    if (reviewCases) {
      const approved = reviewCases.filter((clientCase) => clientCase.statuses && clientCase.statuses.length > 0 && _.maxBy(clientCase.statuses, 'created_date')?.state === 'APPROVED');
      const simple = approved.filter((approved) => approved.type === 'simple');
      const complex = approved.filter((approved) => approved.type === 'complex')
      const psych = approved.filter((approved) => approved.type === 'psychological')
      setApprovedCases({ total: approved.length, simple: simple.length, complex: complex.length, psyh: psych.length });
    }
  }, [reviewCases]);


  return (
    <>
      {/* <SectionContainer text='Quick Links'> */}
      {/* <>
          {getButtonListItem('Add Client', () => setShowAddClientDialog(true), <PersonAddAlt1 />)}
          {getButtonListItem('Create Case', () => showCaseDialog(), <Inventory />)}
          {getButtonListItem('Create Session', () => showSessionDialog(), <Favorite />)}
        </> */}
      {/* </SectionContainer> */}
      <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', flexDirection: 'column' }}>
        <SectionContainer text='Review Cases'>
          <>
            {getProgressListItem('Simple Cases', simpleCases, approvedCases.simple, 10, user)}
            {getProgressListItem('Complex Cases', complexCases, approvedCases.complex, 10, user)}
            {getProgressListItem('Psychology Cases', psychCases, approvedCases.psyh, 5, user)}
            <Box sx={{ mb: 3.5 }} />
          </>
        </SectionContainer>
        <SectionContainer text=''>
          <>
            {getButtonListItem('Public Notice', () => setShowPublicNotice(true), <Public sx={{ mt: 0.2 }} />)}
          </>
        </SectionContainer>
      </Box>
    </>
  )
}

export default function Navigator(props: DrawerProps) {
  const { ...other } = props
  const { user } = React.useContext(UserContext);
  const { mainWaivers } = React.useContext(WaiverContext);
  const {
    setShowAddClientDialog,
    showCaseDialog,
    showSessionDialog,
    showHealingWaiverDialog,
    setShowHealingWaiverDialog,
    showPrivacyWaiverDialog,
    setShowPrivacyWaiverDialog,
    showPublicNotice,
    setShowPublicNotice
  } = React.useContext(DialogsContext);


  return (
    <Drawer variant='permanent' {...other}>
      <List disablePadding sx={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between', }} >
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
          <ListItemIcon sx={{ marginRight: 1, marginBottom: 0.5 }}>
            <AutoGraph />
          </ListItemIcon>
          Prana Records
        </ListItem>
        <ListItem disablePadding >
          {/* <ListItemButton selected={true} sx={{ ...item, ...itemCategory }}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText sx={{ marginTop: 1.3 }}>Dashboard</ListItemText>
          </ListItemButton> */}

        </ListItem>

        {user.is_case_reviewer ? <NavReviewerContent /> : <NavHealerConent />}

      </List>
      {/* {mainWaivers[0] && <WaiverDialog waiver={mainWaivers[0]} show={showPrivacyWaiverDialog} setShow={setShowPrivacyWaiverDialog} />}
      {mainWaivers[1] && <WaiverDialog waiver={mainWaivers[1]} show={showHealingWaiverDialog} setShow={setShowHealingWaiverDialog} />} */}
      <WaiverDialog waiver={publicNotice} show={showPublicNotice} setShow={setShowPublicNotice} />
    </Drawer >
  )
}