import React, { SetStateAction, createContext, useEffect, Dispatch, useState, useMemo, useContext } from 'react'
import { WaiverResult, WaiverType } from '../types'
import { AuthContext } from './authContext';
import { useAxiosInstance } from '../api/AxiosInstanceContext';


type tempWaiverResult = { id: number, accepted: string, waiver: WaiverType };

interface WaiverContext {
  mainWaivers: WaiverType[]
  waiverResults: WaiverResult[] | null
  waiverResultsError: string | null
  savedWaiversResults: WaiverResult[] | null,
  saveWaiversResults: (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => void
  saveWaiversResultsError: string | null
  setSaveWaiversResultsError: Dispatch<SetStateAction<string | null>>
  areWaiversAccepted: boolean | undefined,
  waiversTempResults: tempWaiverResult[],
  setWaiversTempResults: Dispatch<SetStateAction<tempWaiverResult[]>>,
}

export const WaiverContext = createContext<WaiverContext>(undefined as any)

interface Props {
  children: React.ReactNode
}

export const WaiverProvider: React.FC<Props> = ({ children }) => {
  const axiosInstance = useAxiosInstance();
  const { idToken } = useContext(AuthContext)
  const [waiverResults, setWaiverResults] = useState<WaiverResult[] | null>(null);
  const [waiverResultsError, setWaiverResultsError] = useState<string | null>(null);
  const [mainWaivers, setMainWaivers] = useState([] as WaiverType[]);
  const [mainWaiversError, setMainWaiversError] = useState<string | null>(null);
  const [savedWaiversResults, setSavedWaiversResults] = useState(null);
  const [saveWaiversResultsError, setSaveWaiversResultsError] = useState<string | null>(null);

  const [areWaiversAccepted, setAreWaiversAccepted] = useState<boolean | undefined>();
  const [waiversTempResults, setWaiversTempResults] = useState<tempWaiverResult[]>([] as tempWaiverResult[]);

  useEffect(() => {
    axiosInstance.get('/waivers/')
      .then(({ data }) => {
        setMainWaivers(data);
      })
      .catch((e) => {
        setMainWaiversError(e)
      });
  }, [idToken])

  useEffect(() => {
    axiosInstance.get('/waivers_results/')
      .then(({ data }) => {
        setWaiverResults(data)
      })
      .catch((e) => {
        console.log('waivers_results error: ' + e);
        setWaiverResultsError(e)
      });
  }, [idToken]);

  useEffect(() => {
    if (savedWaiversResults) {
      setWaiverResults(savedWaiversResults);
    }
  }, [savedWaiversResults])

  const saveWaiversResults = (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    // const newResultsIdsArray = waiversTempResults.map((result) => result.id);
    // const newResults: tempWaiverResult[] = [];
    // waiverResults.map((result: { id: number, accepted: string | null }) => {
    //   if (!newResultsIdsArray.includes(result.id)) {
    //     newResults.push({ id: result.id, accepted: 'N' });
    //   }
    // });
    setLoading(true);
    axiosInstance.put('/waivers_results/', JSON.stringify({ waivers_results: waiversTempResults }))
      .then(({ data }) => setSavedWaiversResults(data))
      .catch((e) => setSaveWaiversResultsError(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (waiverResults) {
      setAreWaiversAccepted(waiverResults.length < 1)
    }
  }, [waiverResults])

  const value = {
    mainWaivers,
    waiverResults,
    waiverResultsError,
    savedWaiversResults,
    saveWaiversResults,
    saveWaiversResultsError,
    setSaveWaiversResultsError,
    areWaiversAccepted,
    waiversTempResults,
    setWaiversTempResults,
  };

  return <WaiverContext.Provider value={value}>{children}</WaiverContext.Provider>
}