import * as React from 'react'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from '@mui/material'
import { ClientsContext } from '../context/clientsContext'
import { CasesContext } from '../context/casesContext'
import { DialogsContext } from '../context/dialogsContext'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { CaseType, User, WaiverType, caseTypeOptions, CaseTypeType } from '../types'
import { LoadingButton } from '@mui/lab'
import WaiverCheckboxDialog from './WaiverCheckboxDialog.tsx'
import { WaiverContext } from '../context/waiversContext'
import { method } from 'lodash'
import ConfirmDialog from './ConfirmDialog'

// Healer Waiver
const healerCaseWaiverId = 3;

export default function SaveCaseDialog() {
  const { showCaseDialog, caseDialogState, showSessionDialog } = React.useContext(DialogsContext);
  const { saveCase, savedCase, savedCaseError } = React.useContext(CasesContext);
  const { clients } = React.useContext(ClientsContext);
  const [loading, setLoading] = React.useState(false);
  const { mainWaivers } = React.useContext(WaiverContext);
  const [healerCaseWaiver, setHealerCaseWaiver] = React.useState<WaiverType | null>(null);
  const [createSessions, setCreateSession] = React.useState(false);
  const [savedCaseClient, setSavedCaseClient] = React.useState<User | null>(null);


  type AddCaseType = {
    name: string;
    client: User | undefined;
    type: CaseTypeType | undefined;
    accepted_waiver: boolean;
  }

  React.useEffect(() => {
    if (mainWaivers) {
      const healingWaiver = mainWaivers.find((mw) => mw.id === healerCaseWaiverId);
      setHealerCaseWaiver(healingWaiver || null);
    }
  }, [mainWaivers])

  const { control, setValue, getValues } = useForm<AddCaseType>();

  const handleClose = () => {
    showCaseDialog({ show: false });
    setValue('name', '');
    setValue('type', undefined);
    setValue('client', undefined);
    setValue('accepted_waiver', false);
    setLoading(false);
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    // values.client = values.client;
    // values.type = values.type.toLowerCase()
    const { client, name, type, accepted_waiver } = getValues();
    if (client != null && type) {
      const newCase = {
        name: name,
        client_id: client.id,
        type: type.label.toLowerCase(),
        accepted_waiver_id: healerCaseWaiverId
      }
      await saveCase(newCase)
        .catch(() => {
          console.error('handle healer waiver submit: ' + e);
          setLoading(false);
        })
      setSavedCaseClient(client);
      handleClose();
      setCreateSession(true);
    }
    setLoading(false);
  }

  React.useEffect(() => {
    if (caseDialogState && caseDialogState.clientId) {
      const client = clients.find((c) => c.id === caseDialogState.clientId);
      setValue('client', client);
    }
  }, [caseDialogState])


  // React.useEffect(() => {
  //   setLoading(false);
  // }, [savedCase, savedCaseError]);

  // React.useEffect(() => {
  //   if (caseDialogState.clientId) {
  //     setValues({

  //     })
  //   }
  // }, [caseDialogState])


  // const getInitialValue = () => {
  //   if (caseDialogState.clientId) {
  //     const client = clients?.find((client) => client.id === caseDialogState.clientId);
  //     if (client) {
  //       return {
  //         id: undefined,
  //         name: undefined,
  //         clientId: client.id,
  //         client: client,
  //         type: undefined,
  //         description: undefined,
  //       }
  //     }
  //   }
  //   return {
  //     id: undefined,
  //     name: undefined,
  //     clientId: undefined,
  //     client: undefined,
  //     type: undefined,
  //     description: undefined,
  //   }
  // }

  // const isEmpty = (val: string) => { }

  // const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: theme.palette.common.white,
  //     color: 'rgba(0, 0, 0, 0.87)',
  //     boxShadow: theme.shadows[1],
  //     fontSize: 18,
  //     textAlign: 'center',
  //   },
  // }));

  return (
    <div>
      <Dialog open={!!caseDialogState.show} onClose={handleClose} keepMounted={false}>
        <DialogTitle>{'Create New Case'}</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            If the clients email was entered when they were added to Prana Records they will receive a request through email to fill out a healing waiver and other details.
          </DialogContentText>
          <Box m={2} />
          <form onSubmit={(e) => handleSubmit(e)}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Case Name (avoid using client name)"
                  variant="outlined"
                  {...field}
                  required
                />
              )}
            />
            <Box m={1} />
            <Controller
              control={control}
              name='client'
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  noOptionsText={'No Clients'}
                  onChange={(event, client) => {
                    onChange(client)
                  }}
                  value={value}
                  options={clients}
                  getOptionLabel={(client) => (client ? client.full_name || client.email : '')}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Client'
                      margin='normal'
                      variant='outlined'
                      required
                    />
                  )}
                />
              )}
            />
            <Controller
              control={control}
              name='type'
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  noOptionsText={'No Types'}
                  onChange={(event, type) => onChange(type)}
                  // value={value || false}
                  options={caseTypeOptions}
                  getOptionLabel={(type) => (type ? type.label : '')}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Case Type'
                      margin='normal'
                      variant='outlined'
                      required
                    />
                  )}
                />
              )}
            />
            <Box m={2} />
            <WaiverCheckboxDialog
              title={healerCaseWaiver ? healerCaseWaiver.title : ''}
              copy={healerCaseWaiver ? healerCaseWaiver.copy : ''}
              label={'I Accept Healing Waiver'}
              formMethods={{ control, setValue, getValues }}
              name={'accepted_waiver'}
              required={true} />
            <Box m={2} />
            <Grid item>
              <LoadingButton loading={loading} variant='contained' type='submit' color='primary' fullWidth>
                Create
              </LoadingButton>
              <Box m={2} />
              <Button variant='text' onClick={handleClose} color='primary' fullWidth>
                Cancel
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        open={createSessions}
        title="Create Session"
        message="Would you like to create a session for your new case?"
        onConfirm={() => {
          if (savedCase && savedCaseClient) {
            showSessionDialog({ show: true, caseId: savedCase.id, clientId: savedCaseClient.id });
          } else {
            showSessionDialog({ show: true });
          }
          setCreateSession(false);
        }}
        onCancel={() => {
          setCreateSession(false);
        }}
      />
    </div>
  )
}
