import Typography from '../LandingPage/Typography'
import CustomAppBar from './CustomAppBar'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'
import React, { useContext, useState } from 'react'
import { Box, Button, Container, Grid, SxProps, Theme, styled, useMediaQuery } from '@mui/material'
import { LoginDialogsContext } from '../../context/loginDialogsContext'
import { Copyright } from '../../componenents/Copyright'

const backgroundImage =
  'https://images.unsplash.com/photo-1528753053312-73f5af145136?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80'


export default function LandingPage() {
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { setShowClientLogin, setShowHealerLogin } = useContext(LoginDialogsContext);

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <CustomAppBar />
        <Box sx={{
          color: theme.palette.common.white,
          // position: 'relative',
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.up('sm')]: {
            height: '100vh',
          },
        }}>
          <Container
            sx={{
              mt: 3,
              mb: 14,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img style={{ display: 'none' }} src={backgroundImage} alt='increase priority' />
            <Typography color='inherit' align='center' variant={isSmDown ? 'h6' : 'h3'} marked='center'>
              Track your Success
            </Typography>
            <Typography
              color='inherit'
              align='center'
              variant={isSmDown ? 'body1' : 'h6'}
              sx={{ mb: 4, mt: { xs: 4, sm: 6 } }}
            >
              Pranic Healing Certification Program
            </Typography>
            <Grid container display={'flex'} flex={1} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
              <Grid item xs={12} sm={6} md={6} display={'flex'} flex={1} sx={{ justifyContent: isSmDown ? 'center' : 'flex-end' }}>
                <Button sx={{ m: 1 }} variant="contained" onClick={() => setShowClientLogin(true)}>Client Login</Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6} display={'flex'} flex={1} sx={{ justifyContent: isSmDown ? 'center' : 'flex-start' }}>
                <Button sx={{ m: 1 }} variant="contained" onClick={() => setShowHealerLogin(true)}> Healer Login</Button>
              </Grid>
            </Grid>
            <Box sx={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundPosition: 'center',
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              zIndex: -2,
            }} />
          </Container>
        </Box>
        <Copyright color={'white'} />
      </React.Fragment>
    </ThemeProvider >
  )
}
