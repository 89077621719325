import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState,
  } from 'react'
import { CohortSubscriptionType } from '../types';
import { useAxiosInstance } from '../api/AxiosInstanceContext';
import { CurrentCohortSubscriptionContext } from './currentCohortSubscriptionContext';
  
interface CohortsSubscriptionsContext {
  cohortsSubscriptions: CohortSubscriptionType[];
  setCohortsSubscriptions: Dispatch<SetStateAction<CohortSubscriptionType[]>>;
  cohortsSubscriptionsError: string | null;
}

export const CohortsSubscriptionsContext = createContext<CohortsSubscriptionsContext>(undefined as any)

interface Props {
  children: React.ReactNode
}

export const CohortsSubscriptionsProvider: React.FC<Props> = ({ children }) => {
  const axiosInstance = useAxiosInstance();
  const { currentCohortSubscription, setCurrentCohortSubscription } = useContext(CurrentCohortSubscriptionContext);
  const [cohortsSubscriptions, setCohortsSubscriptions] = useState([] as CohortSubscriptionType[]);
  const [cohortsSubscriptionsError, setCohortsSubscriptionsError] = useState<string | null>(null);

  useEffect(() => {
    axiosInstance.get('/cohorts_subscriptions')
      .then((request) => {
        setCohortsSubscriptions(request.data)
      }).catch((e) => setCohortsSubscriptionsError(e));
  }, []);

  useEffect(() => {
    if (cohortsSubscriptions && cohortsSubscriptions.length > 0 && !currentCohortSubscription) {
      const activeSubscription = cohortsSubscriptions.find(s => s.state === 'ACTIVE');
      if (activeSubscription) {
        setCurrentCohortSubscription(activeSubscription);
      } else {
        const completeSubscription = cohortsSubscriptions.find(s => s.state === 'COMPLETE');
        completeSubscription && setCurrentCohortSubscription(completeSubscription);
      }
    } 
  }, [cohortsSubscriptions]);

  const value = {
    cohortsSubscriptions,
    setCohortsSubscriptions,
    cohortsSubscriptionsError
  }

  return <CohortsSubscriptionsContext.Provider value={value}>{children}</CohortsSubscriptionsContext.Provider>;
}
