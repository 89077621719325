import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useState,
  } from 'react'
import { CohortSubscriptionType } from '../types';
  
interface CurrentCohortSubscriptionContext {
  currentCohortSubscription: CohortSubscriptionType | undefined;
  setCurrentCohortSubscription: Dispatch<SetStateAction<CohortSubscriptionType | undefined>>;
}

export const CurrentCohortSubscriptionContext = createContext<CurrentCohortSubscriptionContext>(undefined as any)

interface Props {
  children: React.ReactNode
}

export const CurrentCohortSubscriptionProvider: React.FC<Props> = ({ children }) => {
  const [currentCohortSubscription, setCurrentCohortSubscription] = useState<CohortSubscriptionType>();

  const value = {
      currentCohortSubscription,
      setCurrentCohortSubscription,
  }

  return <CurrentCohortSubscriptionContext.Provider value={value}>{children}</CurrentCohortSubscriptionContext.Provider>;
}
