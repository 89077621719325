import React, { SetStateAction, createContext, useEffect, Dispatch, useContext, useState } from 'react'
import { SessionType } from '../types'
import { useAxiosInstance } from '../api/AxiosInstanceContext'

interface SessionsContext {
  sessions: SessionType[]
  savedSession: SessionType | null
  setSavedSession: Dispatch<SetStateAction<SessionType | null>>
  sessionsError: string | null
  saveSession: (sessionData: Partial<SessionType>) => Promise<void>
  editSession: (sessionId: number, sessionData: Partial<SessionType>) => Promise<void>
  savedSessionError: string | null
  setSavedSessionError: Dispatch<SetStateAction<string | null>>
  refreshSessions: () => void;
  getSession: (sessionId: number) => SessionType | undefined;
  deleteSession: (sessionId: number) => void;
}

export const SessionsContext = createContext<SessionsContext>(undefined as any)

interface Props {
  children: React.ReactNode
}

export const SessionsProvider: React.FC<Props> = ({ children }) => {
  const axiosInstance = useAxiosInstance();
  const [sessions, setSessions] = useState<SessionType[] | null>(null);
  const [error, setError] = useState(null);
  const [savedSession, setSavedSession] = useState<SessionType | null>(null);
  const [savedSessionError, setSavedSessionError] = useState<string | null>(null);
  const [editedSession, setEditSession] = useState(null);
  const [editSessionError, setEditSessionError] = useState(null);

  useEffect(() => {
    axiosInstance.get('/sessions')
      .then(({ data }) => {
        setSessions(data);
      })
      .catch((e) => {
        setError(e);
        setSessions([]);
      });
  }, []);

  const refreshSessions = () => {
    axiosInstance.get('/sessions')
      .then(({ data }) => {
        setSessions(data);
      })
      .catch((e) => {
        setError(e);
        setSessions([]);
      });
  };

  const saveSession = async (sessionData: Partial<SessionType>) => {
    if (sessions) {
      const { data } = await axiosInstance.post('/session/', JSON.stringify({ ...sessionData }));
      const newSessionsArray = sessions.map((obj: any) => obj.id === data.id ? data : obj);
      setSavedSession(data);
      setSessions(newSessionsArray);
    }
  }

  const editSession = async (sessionId: number, sessionData: Partial<SessionType>) => {
    if (sessionId) {
      await axiosInstance.post('/session/', JSON.stringify({ ...{ id: sessionId }, ...sessionData }))
        .catch((e) => console.warn(e));
    } else {
      setSavedSessionError('session id missing');
    }
  }

  const getSession = (sessionId: number) => {
    if (sessionId && sessions && sessions !== undefined) {
      return sessions.find((s: SessionType) => s.id === sessionId);
    }
  }

  const deleteSession = async (sessionId: number) => {
    if (sessionId) {
      await axiosInstance.delete('/session/', { data: JSON.stringify({ session_id: sessionId }) })
        .then(() => {
          setSessions(sessions?.filter((s) => s.id !== sessionId) || []);
        })
        .catch((e) => console.warn(e));
    } else {
      setSavedSessionError('session id missing');
    }
  }

  const value = {
    sessions: sessions ? (sessions as SessionType[]) : ([] as SessionType[]),
    savedSession: savedSession,
    setSavedSession,
    sessionsError: error,
    saveSession,
    editSession,
    savedSessionError: savedSessionError,
    setSavedSessionError,
    refreshSessions,
    getSession,
    deleteSession,
  }

  return <SessionsContext.Provider value={value}>{children}</SessionsContext.Provider>
}
