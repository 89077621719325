import React, {
  createContext,
  useEffect,
  useState,
} from 'react'
import { User } from '../types'
import { useAxiosInstance } from '../api/AxiosInstanceContext';

interface UserContext {
  user: User;
  userError: string | null;
  setData: React.Dispatch<any>;
}

export const UserContext = createContext<UserContext>(undefined as any)

interface Props {
  children: React.ReactNode
}

export const UserProvider: React.FC<Props> = ({ children }) => {
  const axiosInstance = useAxiosInstance();
  const [data, setData] = useState(null);
  const [error, setError] = useState<string | null>(null);


  useEffect(() => {
    axiosInstance.get('/profile')
      .then((request) => {
        setData(request.data)
      }).catch((e) => setError(e));
  }, []);

  const value = {
    user: data ? data : {} as User,
    userError: error,
    setData
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
